import styled from 'styled-components';

import flowSettings from 'settings/flow-settings';

const { primaryColor } = flowSettings;

const StyledCookiePolicy = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'CircularStd-Book';

  .header {
    display: flex;
    justify-content: center;
  }

  .title {
    width: 100%;
    max-width: 400px;
  }

  .text, .topic, .list {
    margin-bottom: 16px;
    text-align: justify;
  }

  .text {
    font-weight: normal;
  }

  .topic {
    color: ${primaryColor};
    font-size: 16px;
    font-weight: bold
  }

  .list {
    list-style: none;
    margin-left: 32px;
    li {
      margin-bottom: 8px;
      font-weight: bold
    }
  }
`;

export default StyledCookiePolicy;
