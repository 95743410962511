import React, { Suspense } from 'react';
import Routes from './routes';

import AppContext from './contexters/AppContext';

import './App.css';

function App() {
  return (
    <AppContext>
      <Suspense
        fallback={
          (
            <div className="suspense-loading">
              <p>...</p>
            </div>
          )
        }
      >
        <Routes />
      </Suspense>
    </AppContext>
  );
}

export default App;
