import logo from 'assets/elle-assets/images/logo-color1.png';

const elleSettings = {
  appName: 'Elle',
  appUrl: 'https://app.elleconsulting.com.br',

  // Assets
  logo,

  // Colors
  primaryColor: '#f60000',
  secondaryColor: '#f60000',
  tertiaryColor: '#8f9194',
  quaternaryColor: '#F6F6FB',
  darkColor: '#4a4a4a',
  whiteColor: '#ffffff',
  dangerColor: '#D03A41',
  warningColor: '#F1CC07',
  successColor: '#0AE569',
  infoColor: '#5C73F2',
};

export default elleSettings;
