import logo from 'assets/talkative-assets/images/logo-color@2x.png';

const talkativeSettings = {
  appUrl: 'https://app.talkative.media/',

  // Assets
  logo,

  // Colors
  primaryColor: '#00bc9b',
  secondaryColor: '#00bc9b',
  tertiaryColor: '#8f9194',
  quaternaryColor: '#F6F6FB', // Main background and divisors color
  darkColor: '#4a4a4a',
  whiteColor: '#ffffff',
  dangerColor: '#D03A41',
  warningColor: '#F1CC07',
  successColor: '#0AE569',
  infoColor: '#5C73F2',
};

export default talkativeSettings;
