import logo from 'assets/flow-assets/images/logo-color@2x.png';

const flowSettings = {
  appUrl: 'https://app.useflow.tech/',

  // Assets
  logo,

  // Colors
  primaryColor: '#363AA3',
  secondaryColor: '#337ab7',
  tertiaryColor: '#8f9194',
  quaternaryColor: '#F6F6FB', // Main background and divisors color
  darkColor: '#4a4a4a',
  whiteColor: '#ffffff',
  dangerColor: '#D03A41',
  warningColor: '#F1CC07',
  successColor: '#0AE569',
  infoColor: '#5C73F2',
};

export default flowSettings;
