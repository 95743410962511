import React, { useEffect } from 'react';

import { Container, Row, Column } from 'components/Grid';

import MainLayout from 'layouts/MainLayout';

import flowSettings from 'settings/flow-settings';

import { useTranslation } from 'react-i18next';

import StyledCookiePolicy from './styles';

function CookiePolicy() {
  const { logo, appUrl } = flowSettings;
  const { t, i18n } = useTranslation();
  // mês/dia/ano
  const lastUpdate = new Date('03/26/2024').toLocaleDateString(
    i18n.language,
    { dateStyle: 'long' },
  );
  
  useEffect(() => {
    function sendHeightMessage() {
      const contentHeight = document.body.scrollHeight;

      window.parent.postMessage({ height: contentHeight }, '*');
    }

    window.addEventListener('load', sendHeightMessage);
    document.title = t('COOKIE_TITLE');

    return () => {
      window.removeEventListener('load', sendHeightMessage);
    };
  }, [t]);

  return (
    <MainLayout className="flow-site" logo={logo} appUrl={appUrl}>
      <StyledCookiePolicy>
        <Container className="mt-3 p-3">
          <Row justifyContent="center">
            <Column mobile="12" tablet="12" desktop="12" className="header">
              <h1 className="text-center mb-3 title">
                {t('COOKIE_TITLE')}
              </h1>
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column mobile="12" tablet="12" desktop="12">
              <h3 className="topic">
                {t('COOKIE_TOPIC_1')}
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_1_TEXT')}
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_2')}
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_2_TEXT')}
              </p>

              <p className="text">
                {`${t('COOKIE_TOPIC_2_LAST_UPDATE')} ${lastUpdate}`}
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_3')}
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_3_TEXT')}
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_4')}
              </h3>

              <ul className="list">
                <li>
                  {t('COOKIE_TOPIC_4_LIST_1')}
                </li>

                <li>
                  {t('COOKIE_TOPIC_4_LIST_2')}
                </li>

                <li>
                  {t('COOKIE_TOPIC_4_LIST_3')}
                </li>

                <li>
                  {t('COOKIE_TOPIC_4_LIST_4')}
                </li>
              </ul>

              <h3 className="topic">
                {t('COOKIE_TOPIC_5')}
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_5_TEXT')}
              </p>

              <p className="text">
                {t('COOKIE_TOPIC_5_TEXT_2')}
                <a href="https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</a>
                .
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_6')}
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_6_TEXT')}
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_7')}
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_7_TEXT')}
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_8')}
                
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_8_TEXT')}
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_9')}
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_9_TEXT')}
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_10')}
                
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_10_TEXT')}
              </p>

              <h3 className="topic">
                {t('COOKIE_TOPIC_11')}
                
              </h3>

              <p className="text">
                {t('COOKIE_TOPIC_11_TEXT')}
              </p>
            </Column>
          </Row>
        </Container>
      </StyledCookiePolicy>
    </MainLayout>
  );
}

export default CookiePolicy;
