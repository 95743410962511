import React from 'react';

import Header from '../../components/Header';

import StyledMainLayout from './styles';

function MainLayout({
  children,
  className,
  logo,
  appUrl,
}) {
  return (
    <StyledMainLayout className={className}>
      <Header logo={logo} appUrl={appUrl} />

      {children}
    </StyledMainLayout>
  );
}

export default MainLayout;
