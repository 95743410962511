import styled from 'styled-components';

import variables from 'variables';

const { quaternaryColor } = variables;

const StyledMainLayout = styled.div`
  background-color: ${quaternaryColor};
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-content {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export default StyledMainLayout;
