import flowSettings from 'settings/flow-settings';
import elleSettings from 'settings/elle-settings';
import talkativeSettings from 'settings/talkative-settings';

import { APP_NAME } from './settings';

const generalVariables = {};

const flowVariables = {
  ...generalVariables,
  ...flowSettings,
};

const elleVariables = {
  ...generalVariables,
  ...elleSettings,
};

const talkativeVariables = {
  ...generalVariables,
  ...talkativeSettings,
};

const siteVariables = {
  flow: flowVariables,
  elle: elleVariables,
  talkative: talkativeVariables,
};

const variables = siteVariables[APP_NAME];

export default variables;
