import styled from 'styled-components';

import variables from 'variables';

const {
  primaryColor,
  whiteColor,
  darkColor,
} = variables;

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${whiteColor};
  color: ${primaryColor};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  .main-content {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .app-logo {
      max-width: 100px;
    }

    a {
      color: ${primaryColor};
    }

    a:hover {
      color: ${darkColor};
    }
  }

`;

export default Header;
