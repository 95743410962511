import React from 'react';

import { Container, Row, Column } from '../Grid';

import useApp from '../../hooks/useApp';

import StyledHeader from './styles';

function Header({
  logo,
  appUrl,
  children,
}) {
  const app = useApp();

  return (
    <StyledHeader className="header">
      <Container className="main-content">
        <Row>
          <Column desktop="2" tablet="2" mobile="2">
            <a href={appUrl || '#'}>
              {logo ? <img src={logo} className="app-logo" alt="Logo" /> : app.appName}
            </a>
          </Column>
          <Column desktop="10" tablet="10" mobile="10" className="header-content">
            {children}
          </Column>
        </Row>
      </Container>
    </StyledHeader>
  );
}

export default Header;
