import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import FlowUseTerms from 'pages/Flow/UseTerms';
import FlowCookiePolicy from 'pages/Flow/CookiePolicy';

import Public from './public';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Public path="/flow/use-terms" exact component={FlowUseTerms} />
      <Public path="/flow/cookie-policy" exact component={FlowCookiePolicy} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
